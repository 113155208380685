import React from 'react';
import {LanguageProvider, Translation} from "./ApplicationFrame/Translation";
import {LanguageSelect} from "./ApplicationFrame/languageSelect";


export const App = props =>
    <LanguageProvider>
      <Translation>
        <LanguageSelect/>{ props.children }
      </Translation>
    </LanguageProvider>;
