import React, {useContext, useEffect, useState} from 'react';
import {setLocale, setTranslations} from "react-i18nify";
import {STATUS} from "./index";
import {Cookie} from "./Cookie";
import {Server} from "./Server";


export const LanguageContext = React.createContext();



export const SUPPORTED_LANGUAGES = ["en", "de", "ru", "la"];

export const Translation = props => {

    const [translation, setTranslation] = useState({});
    const [status, setStatus] = useState(STATUS.unknowned);
    const { setLanguage } = useContext(LanguageContext);


    const getLocalLanguage = () => {
        const languageBrowser = navigator.language || navigator.userLanguage;
        return languageBrowser.slice(0,2);
    }
    const languageBrowser = getLocalLanguage();

    const getTranslations = languages => {
        languages.forEach(language =>
            Server.getTranslationFor(language).then(response => {
                translation[language] = response.data;
                setTranslation({...translation});
            }))
    };

    useEffect( () => {
        if( status === STATUS.loading && Object.keys(SUPPORTED_LANGUAGES).length === Object.keys(translation).length )
            setStatus( STATUS.loaded );
    }, [translation]);

    useEffect(() => {

        switch( status ){

            case STATUS.unknowned:
                getTranslations( SUPPORTED_LANGUAGES );
                setStatus(STATUS.loading);
                break;

            case STATUS.loaded:
                setTranslations(translation);
                const acceptedPresetLanguage =
                    [ Cookie.get().lang, languageBrowser, 'en' ]
                    .find( lang => Object.keys( translation ).includes( lang ) );

                setLanguage( acceptedPresetLanguage );
                setStatus( STATUS.complete );
                break;
        }

    }, [status]);

    if( status === STATUS.complete )
        return props.children;
}

export const LanguageProvider = ({ children, lang }) => {
    const [language, _setLanguage] = useState(lang || 'en' );

    SUPPORTED_LANGUAGES
        .map( lang => MomentLanguage(lang) )
        .forEach( lang => require('moment/locale/' + (lang === 'la' ? 'de' : lang) + '.js') );

    const setLanguage = language => {
        Cookie.set("lang", language );
        _setLanguage( language );
        setLocale( language );
    };

    return (
        <LanguageContext.Provider value={{ language: language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const MomentLanguage = language =>
    language === 'en' ? 'en-gb' : language;