
const readCookie = () => {
    if( (""+document.cookie).length <= 0 )
        return {};

    return document.cookie
        .split(";")
        .filter( a => a.includes("=" ))
        .reduce( (acc,cur) => {
            const pair = cur.split("=");
            acc[pair[0].trim()] = pair[1].trim();
            return acc;
        }, {});
}
const saveCookie = (key,value) => {
    const cookie = readCookie();
    cookie[key] = value;

    Object.keys( cookie )
        .forEach( key => {
            document.cookie = key + "=" + cookie[key] + ";path=/";
        });
}
export const Cookie = {
    get: readCookie,
    set: saveCookie,
}