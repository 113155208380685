import axios from "axios";

export const isDevelopment = document.location.host === "localhost:3000";

const _Server = {

    onError: () => window.location.href = "https://error.super-dupa-pinky.de",

    onLoginSuccess: () => window.location.href = "/admin",

    getTranslationFor: (language) =>
        axios.get("/translations/login."+language+".json")

}
const _DevelopmentServer = {

    onLoginSuccess: () => window.location.href = "/admin",

    getTranslationFor: (language) =>
        axios.get("/translations/login."+language+".json").catch(_Server.onError)
}

export const Server = isDevelopment ? _DevelopmentServer : _Server;