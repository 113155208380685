import React from "react";
import {SUPPORTED_LANGUAGES} from "./Translation";
import useLanguage from "../useHooks/useLanguage";

export const LanguageSelect = () => {

    const {language, setLanguage} = useLanguage();

    if( ! SUPPORTED_LANGUAGES || SUPPORTED_LANGUAGES.length === 1 )
        return;

    const onUpdate = value => {
        setLanguage( value.target.value );
    }

    return <div className={"languageSelect"}>
        <select name="language" id="pet-select" onChange={onUpdate} value={language}>
            { SUPPORTED_LANGUAGES
                .sort( (a,b) => a.localeCompare(b, language) )
                .map( lan =>
                    <option key={lan} value={lan}>{ new Intl.DisplayNames([lan], { type: 'language' }).of(lan) }</option>)
            }
        </select>
    </div>
}