import React, {useContext, useEffect, useState} from 'react';
import {LanguageContext} from "../ApplicationFrame/Translation";
import {translate} from "react-i18nify";
import {Submit} from "../ApplicationFrame/Buttons";
import {Cookie} from "../ApplicationFrame/Cookie";
import {Server} from "../ApplicationFrame/Server";

const Login = () => {
    const { language } = useContext(LanguageContext);
    useEffect( () => {}, [language]);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const tryLogin = () => {
        Cookie.set( "auth", "d41d8cd98f00b204e9800998ecf8427e" );
        setTimeout( () => Server.onLoginSuccess(), 3000 );
    }

    return <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100vw", marginTop: 150}}>
        <div style={{textAlign: "center", minWidth: 300, maxWidth: 600, margin: 20, padding: 20, border: "2px solid green", borderRadius: 15}}>
            <table><tbody style={{textAlign: "left"}}>
                <tr>
                    <td>{ translate("login") }</td>
                    <td style={{paddingLeft: 10, paddingRight: 10}}>:</td>
                    <td><input className={"LoginInput"} type={"text"} onChange={email => setEmail(email.target.value)} /></td>
                </tr>
                <tr style={{height: 10}}></tr>
                <tr>
                    <td>{translate("password")}</td>
                    <td style={{paddingLeft: 10, paddingRight: 10}}>:</td>
                    <td><input className={"PasswordInput"} type={"password"} onChange={password => setPassword(password.target.value)}  /></td>
                </tr>
            </tbody>
            </table>
        </div>
        <Submit onClick={ tryLogin } >{ translate("loginAction") }</Submit>
    </div>
}

export default Login;