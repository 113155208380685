import React from 'react';
import ReactDOM from 'react-dom/client';

import './Styles/Theme-IT.css';
import './Styles/BasicElements.css';

import {App} from "./App";
import Login from "./Login/login";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App>
        <Login />
    </App>
);

